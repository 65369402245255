@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.icon-account {
    &__icon,
    &__wpr {
        position: relative;
        overflow: visible;
		height: 22px;
		width: 22px;

        @include gridle_state(md) {
            height: 28px;
            width: 28px;
        }

        .dot-notification {
            position: absolute;
            top: 0;
            right: 0;
            height: 6px;
            width: 6px;
            border-radius: 100%;
            z-index: 2;
            @include gridle_state(md) {
                height: 8px;
                width: 8px;
            }
        }
    }
}