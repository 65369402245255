@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$hamburger-layer-color: $black !default;
$button-border-radius: 5px !default;

.hamburger-account {
	display: inline-flex;
	align-items: center;
	color: $hamburger-layer-color;
	border: 1px solid $hamburger-layer-color;
	border-radius: $button-border-radius;
	padding: 5px 10px;

	.hamburger {
		margin-right: 10px;
	}

	.hamburger-inner,
	.hamburger-inner::before,
	.hamburger-inner::after {
		background-color: $hamburger-layer-color;
	}

	svg {
		height: 22px;
		width: 22px;
	}

	@include gridle_state(md) {
		padding: 10px 15px;

		svg {
			height: 26px;
			width: 26px;
		}
	}
}
