@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$smartdp-header-filter-box-shadow: none !default;
$menu-account-menu-item-color: $black !default;
$menu-background-color: white !default;
$menu-text-color: $black !default;

.aside-menu-account-flashsale {
	$this: &;

	display: flex;
	flex-direction: column;
	background: $header-background-color;
	height: 100%; // pour que le bouton logout soit en bas
	width: 100vw;
	overflow-y: auto;

	&__header {
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		box-sizing: border-box;
		padding: 20px;
		box-shadow: $smartdp-header-filter-box-shadow;
		background: $header-background-color;
		z-index: 1; // for box-shadow to be visible

		#{$this}__logo {
			.logo-brand {
				height: 35px;
				min-width: 140px;
				width: auto;
			}
		}
		#{$this}__logo-small {
			text-align: center;
			width: 70px;
			background: $color-secondary;
			height: 60px;
			overflow: hidden;
			.logo-brand {
				width: 60px;
				height: 90px;
				transform: translateY(-11px);
			}
		}
	}

	&__close {
		display: flex;
		align-items: center;
		color: $menu-account-menu-item-color;
	}

	.smartdp-menu-authenticated {
		padding-top: 20px;
	}

	.signup-menu-account {
		&__dynamic-message {
			background: white;
		}

		&__body {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 100%;
			background: $header-background-color;
		}

		&__nav-item {
			padding: 0 25px;
			height: 60px;
			display: flex;
			justify-content: flex-start;
			align-items: center;

			&:first-of-type {
				padding-top: 0;
			}

			&:last-of-type {
				border-bottom: 1px solid $grey-medium;
			}

			.relative-link {
				justify-content: flex-start;
				font-size: 1.5rem;
				color: $menu-account-menu-item-color;
				font-weight: 700;
				line-height: 22px;
			}

			& svg {
				margin-right: 20px;
			}
		}
	}
}
