@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.back-button {
	display: inline-flex;
	align-items: center;
	font-size: 1.3rem;
	cursor: pointer;

	.relative-link {
		font-size: 1.3rem;
	}
}
