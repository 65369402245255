@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
/*stylelint-disable*/
$footer-background: $grey-dark !default;
$footer-border: none !default;
$footer-text-color: white !default;
$listing-background-color: $grey-light !default;

.footer {
	position: relative;
	padding: 25px 25px 100px 25px;
	border-top: $footer-border;
	color: $footer-text-color;
	background: $footer-background;

	a {
		color: $footer-text-color;
	}

	@include gridle_state(md) {
		padding: 55px 25px;
		padding-bottom: 25px;
	}

	.advanced-select {
		color: $black;
	}

	&--menu-reverse {
		display: flex;
		flex-direction: column;
		width: 100%;

		@include gridle_state(md) {
			flex-direction: row-reverse;
			justify-content: space-around;
			flex: 7 1 0%;
		}
	}

	&__container {
		@include gridle_state(md) {
			@include container;
			display: flex;
			flex-direction: column;
		}
	}

	&__partner {
		display: flex;
		flex-direction: column;
		align-items: center;

		@include gridle_state(md) {
			align-items: flex-start;
		}

		&-logo {
			.icon--logo-perfectstay-white {
				width: 120px;
			}
		}

		&-title {
			@extend %text-bold;
			text-align: center;
			color: $footer-text-color;
			margin-bottom: 20px;
			letter-spacing: 0.2rem;

			@include gridle_state(md) {
				text-align: left;
			}
		}

		&-text {
			color: $footer-text-color;
			margin-bottom: 5px;
			font-size: 1.07rem;

			@include gridle_state(md) {
				text-align: left;
			}
		}
	}

	&__engagements {
		display: flex;
		flex-direction: column;
	}

	&__separator {
		margin: 30px auto;
		color: $grey-medium;
		opacity: 0.1;
		width: 100%;

		&--copyright {
			@include gridle_state(md) {
				display: none;
			}
		}
	}

	&__footer {
		display: flex;
		align-items: center;
		flex-direction: column;

		@include gridle_state(md) {
			flex-direction: row;
			align-items: flex-start;
			justify-content: space-between;
		}

		+ .footer__separator {
			display: none;

			@include gridle_state(md) {
				display: block;
			}
		}
	}

	&__social {
		width: 100%;

		@include gridle_state(md) {
			flex: 1 1 0%;
			max-width: 180px;
		}

		+ .footer__separator {
			@include gridle_state(md) {
				display: none;
			}
		}

		.reassurance-payment {
			& + .separator {
				background: $grey-light;
				opacity: 0.1;
				width: 100%;
				margin-left: 0;
				margin-right: 0;

				@include gridle_state(md) {
					background: white;
					width: 50px;
					opacity: 1;
					margin-top: 20px;
					margin-bottom: 20px;
				}
			}
		}

		.social {
			text-align: center;

			&__headline {
				@extend %font-bold;
				text-transform: uppercase;
				letter-spacing: 0.2rem;
				margin-bottom: 20px;

				@include gridle_state(md) {
					text-align: left;
					margin-bottom: 10px;
					font-size: 1.2rem;
				}
			}

			&__list {
				display: flex;
				align-items: center;
				justify-content: center;

				@include gridle_state(md) {
					justify-content: flex-start;
				}
			}

			&__item {
				&:first-of-type {
					margin-right: 20px;
				}

				@include gridle_state(md) {
					.icon {
						width: 20px;
						height: 20px;
						cursor: pointer;
					}
				}
			}

			@include gridle_state(md) {
				&__items {
					display: flex;
				}
			}
		}
	}

	&__contact {
		text-align: center;

		@include gridle_state(md) {
			text-align: left;
		}

		+ .footer__separator {
			@include gridle_state(md) {
				display: none;
			}
		}

		.footer-contact {
			&__headline {
				@extend %font-bold;
				text-transform: uppercase;
				letter-spacing: 0.2rem;
				margin-bottom: 20px;

				@include gridle_state(md) {
					text-align: left;
					margin-bottom: 0;
					font-size: 1.2rem;
				}
			}

			.help-desk-phone-number {
				color: $footer-text-color;

				&__number {
					@include gridle_state(md) {
						justify-content: flex-start;
						font-size: 2.5rem;
					}
				}

				&__link {
					color: $footer-text-color;
				}

				&__info {
					@include gridle_state(md) {
						text-align: left;
						max-width: 250px;
					}
				}

				&__international {
					.help-desk-phone-number__link {
						color: $footer-text-color;
						font-size: 2rem;
					}

					&__number {
						&__part {
							margin-left: 10px;

							&:first-of-type {
								margin-left: 0;
							}
						}
					}
				}

				.separator {
					background-color: $footer-text-color;
					margin: 10px auto;

					@include gridle_state(md) {
						margin: 10px 0;
					}
				}

				.click-to-call-cta {
					&__container {
						align-items: center;

						@include gridle_state(md) {
							align-items: flex-start;
						}
					}

					&__button {
						color: $footer-text-color;
						background: none;
						box-shadow: #{"inset 0px 0px 0px 1px "}$footer-text-color#{", 0 3px 6px 0 rgb(255 255 255 / 16%)"};

						&:hover {
							background: $footer-text-color;
							color: $black;
						}
					}
				}
			}
		}

		@include gridle_state(md) {
			order: 3;
		}
	}

	&__row {
		@include gridle_state(md) {
			display: flex;
		}
	}

	&__menu {
		@include gridle_state(md) {
			display: flex;
			flex-direction: column;
		}

		.footer-menu {
			@include gridle_state(md) {
				margin-right: 30px;
			}

			&__item {
				.relative-link {
					@extend %font-bold;
					text-align: center;
					justify-content: center;
					text-transform: uppercase;
					margin-bottom: 10px;

					@include gridle_state(md) {
						justify-content: flex-start;
						text-align: left;
						font-size: 1rem;
					}
				}
			}
		}
	}

	&__note {
		font-size: 1rem;
		text-align: justify;
		margin-top: 20px;
		color: $footer-text-color;

		a {
			font-size: 1rem;
			color: $footer-text-color;
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.footer-pictos {
		.separator {
			margin-left: auto;

			@include gridle_state(md) {
				margin-left: 0;
			}
		}
	}

	.footer-copyright {
		display: flex;
		align-items: center;
		margin-bottom: -25px;

		.icon {
			height: 60px;
			width: 85px;
			margin-left: 20px;
		}

		@include gridle_state(md) {
			justify-content: space-between;
			margin-bottom: 0;
			align-items: flex-end;

			&__text {
				flex: 1 1 0%;
				border-top: 1px solid #4e4e4e;
				padding-top: 30px;
				margin-bottom: 10px;
			}

			.icon {
				height: 100px;
				width: 80px;
			}
		}
	}

	&__atol {
		position: relative;

		.atol-protected {
			background: $listing-background-color;
			border-top: 5px solid white;
			padding: 0 10px;

			&--big {
				@include container;
				flex-direction: column;
				margin: 25px auto;

				@include gridle_state(md) {
					flex-direction: row;
				}

				&__message {
					display: flex;
					flex-direction: column;
					align-items: center;
					text-align: center;
					margin-top: 5px;

					@include gridle_state(md) {
						text-align: left;
						align-items: flex-start;
					}
				}

				&__title {
					@extend %text-bold;
					font-size: 2rem;
				}

				&__subTitle {
					font-size: 1.2rem;
				}

				svg {
					margin-right: 5px;
					width: 80px;
					height: 80px;
					@include gridle_state(md) {
						width: 350px;
					}
				}
			}

			.separator {
				display: none;
			}

			@include gridle_state(md) {
				padding: 0;
			}
		}
	}

	&__language {
		margin-bottom: 20px;

		@include gridle_state(md) {
			margin-top: 10px;
			width: 210px;

			.advanced-select {
				// Hack pour afficher le frop down en haut au lieu d'en bas
				// @see https://github.com/JedWatson/react-select/issues/1636#issue-218255801
				&__menu {
					position: absolute !important;
					top: auto !important;
					bottom: calc(100% - 1px) !important;
				}

				&__value-container {
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
					margin-right: 10px;
				}
			}
		}
	}

	&__reassurance-payment-label {
		@extend %font-bold;
		margin-top: 5px;
		text-transform: uppercase;

		@include gridle_state(md) {
			margin-top: 0;
			margin-left: 10px;
			font-size: 1rem;
		}
	}

	&__reassurance-payment-headline {
		text-align: center;

		@include gridle_state(md) {
			display: flex;
			align-items: center;
			margin-bottom: 10px;

			svg {
				width: 10px;
				height: 16px;
			}
		}
	}

	// style pour écraser les styles onetrust
	#ot-sdk-btn.ot-sdk-show-settings {
		@extend %font-bold;
		background: none;
		border: none;
		color: white;
		text-align: center;
		justify-content: center;
		text-transform: uppercase;
		margin-bottom: 10px;
		padding: 0;
		font-size: 1.4rem;
		height: 15px;
		width: 100%;

		&:hover {
			background: none;
		}

		@include gridle_state(md) {
			justify-content: flex-start;
			text-align: left;
			font-size: 1rem;
			padding-left: 0;
		}
	}
}
