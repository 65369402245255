@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.button-icon {
	color: $black;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;

	&.normal {
		height: 14px;
		width: 14px;

		svg {
			height: 12px;
			width: 12px;
		}

		@include gridle_state(md) {
			height: 15px;
			width: 15px;

			svg {
				height: 15px;
				width: 15px;
			}
		}
	}

	&:hover {
		// color: $active-color;
	}
}
